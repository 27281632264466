import { Component } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as CanvasJs  from './canvasjs.min.js';
import { PhoneNumberService } from './phone-number.service.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'nickTracker';
  apiResponse;
  items;
  lengthOfItems;
  dateMap = new Map();
  phoneMap = new Map();
  dataPoints = new Array();
  phonePoints = new Array();
  phoneNumbers;
  phoneNumbersUsed = new Array();
  constructor(private http: HttpClient, private phoneNumberService: PhoneNumberService) {}

  ngOnInit(): void {

    const url = `https://fmvvkmnsyb.execute-api.us-east-1.amazonaws.com/prod/`;

    this.phoneNumbers = this.phoneNumberService.getPhoneNumbers();
    var self = this;
    this.phoneNumbers.subscribe({
      next(phoneNumbersJson) {
        console.log("Retrieved phone numbers: " + JSON.stringify(phoneNumbersJson));
        self.phoneNumbers = phoneNumbersJson;
      }
    });

    this.http.get<any>(url, {})
      .pipe(
        catchError((e) => this.handleError(e))
      ).subscribe((response)=> {
        console.log("Got response from api!: ", response);
        this.items = JSON.parse(response.body)['items'];
        this.lengthOfItems = this.items.length;

        for (var i = 0; i < this.items.length; i ++) {
          var timestamp = this.items[i]['timestamp']['S'];
          var phoneNumber = this.items[i]['submittedBy']['S'];
          var nameByPhoneNumber = this.phoneNumbers[phoneNumber];
          this.phoneNumbersUsed.push(nameByPhoneNumber);
          console.log("Phone number who submitted this: " + nameByPhoneNumber);

          var phoneCount = 0;
          if (this.phoneMap.has(nameByPhoneNumber)) {
            var currVal = this.phoneMap.get(nameByPhoneNumber);
            phoneCount = currVal + 1;
          } else {
            phoneCount = 1;
          }

          this.phoneMap.set(nameByPhoneNumber, phoneCount);

          var split = timestamp.split(" ");
          var date = split[0];
          var splitDate = date.split("-");
          var year = splitDate[0];
          var month = splitDate[1];
          var day = splitDate[2];

          var dateString = year + "-" + month + "-" + day;

          // var month = timestamp.getMonth();
          // var date = timestamp.getDate();
          // var year = timestamp.getFullYear();

          // var dateTime = String(year) + "/" + String(month) + "/" + String(date);
          // //var dateTime = new Date(year, month, date);
          // var numTimes =  Number(this.items[i]['timesStated']['N'])

          var officialCount = 0;
          if (this.dateMap.has(dateString)) {
            var currVal = this.dateMap.get(dateString);
            officialCount = currVal + 1;
          } else {
            officialCount = 1;
          }

          this.dateMap.set(dateString, officialCount);

        }

        this.phoneMap.forEach((value, keys) => {
          var dataPoint = {
            y: parseFloat(value),
            label: keys
          };
          this.phonePoints.push(dataPoint);
        })

        this.dateMap.forEach((value,keys)=>{ 
          console.log("Values: " + value + ", keys: " + keys);
          // var split = keys.split("/");
          // var year = split[0];
          // var month = split[1];
          // var date = split[2];
          var splitDate = keys.split("-");
          var year = splitDate[0];
          var month = splitDate[1] - 1;
          var day = splitDate[2];
          var dataPoint = {
            y: parseFloat(value), 
            x: new Date(year, month, day)
          };
          this.dataPoints.push(dataPoint);
        })

        console.log("Datapoints: " + this.dataPoints);
        console.log("length: " + this.lengthOfItems);
        dateChart.render();
        phoneChart.render();
      });

      let dateChart = new CanvasJs.Chart("dateChart", {
        animationEnabled: true,
        exportEnabled: true,
        axisX:{      
          valueFormatString: "DD-MMM" ,
          labelAngle: -50
        },
        title: {
          text: "When did Nick say it?"
        },
        data: [{
          type: "column",
          dataPoints: this.dataPoints
        }],
      });

      let phoneChart = new CanvasJs.Chart("phoneChart", {
        animationEnabled: true,
        exportEnabled: true,
        title: {
          text: "Who did Nick say it to??"
        },
        data: [{
          type: "pie",
          startAngle: 240,
          yValueFormatString: "##0",
		      indexLabel: "{label} {y}",
          dataPoints: this.phonePoints
        }],
      });
  }

  private handleError(error: HttpErrorResponse) {
    console.log('error: ', error);
    // return an observable with a user-facing error message
    return throwError(
      'Internal Error.');
  };

}
